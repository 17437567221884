import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BsPeopleFill } from 'react-icons/bs'
import { Modal, Button, Table, Form } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './wakulima.css' // Import your CSS file
import useAPI from '../hooks/useAPI'

const Wakulima = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [foundFarmer, setFoundFarmer] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [newFarmer, setNewFarmer] = useState({
    fullName: '',
    age: '',
    sex: 'male', // default sex
    nationalId: '',
    farmSize: '',
    location: '',
    phone: '',
    county: '',
    constituency: '',
    ward: '',
    village: '',
    agentId: '',
  })
  const [agents] = useAPI('/api/agents')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [farmerList, setFarmerList] = useState([])
  // const [filters, setFilters] = useState({
  //   county: '',
  //   constituency: '',
  //   ward: '',
  //   village: '',
  // })

  const options = [
    { value: 'male', text: 'Male' },
    { value: 'female', text: 'Female' },
  ]

  // const handleChange = (event) => {
  //   setNewFarmer({
  //     ...newFarmer,
  //     [event.target.name]: event.target.value,
  //   })
  // }

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewFarmer((prevFarmer) => ({
      ...prevFarmer,
      [name]: value,
    }))
  }

  const handleSearch = async () => {
    try {
      if (searchTerm.trim() === '') {
        alert('Please enter a valid search term.')
        return
      }

      let response
      if (!isNaN(searchTerm)) {
        response = await axios.get(
          `https://backend.prolocaledge.com/api/farmers/nationalId/${searchTerm}`
        )
      } else {
        response = await axios.get(
          `https://backend.prolocaledge.com/api/farmers/name/${searchTerm}`
        )
      }

      if (response.data.length === 0) {
        alert('No farmer found with the provided ID or name.')
        setFoundFarmer(null)
      } else {
        const farmer = response.data[0]
        setFoundFarmer(farmer)
      }
    } catch (error) {
      console.error('Error searching for farmer:', error)
      alert(
        'An error occurred while searching for the farmer. Please try again.'
      )
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      if (selectedRow) {
        const response = await axios.put(
          `https://backend.prolocaledge.com/api/farmers/${selectedRow.id}`,
          newFarmer
        )
        console.log('Edit farmer:', response.data)
        closeModal()
      } else {
        const response = await axios.post(
          `https://backend.prolocaledge.com/api/farmers`,
          newFarmer
        )
        console.log('New farmer added:', response.data)
        closeModal()
      }

      const updatedResponse = await axios.get(
        `https://backend.prolocaledge.com/api/farmers`
      )
      setFarmerList(updatedResponse.data)
    } catch (error) {
      console.error('An error occurred:', error.message)
    }
  }

  const handleEdit = (farmer) => {
    setSelectedRow(farmer)
    setNewFarmer({
      fullName: farmer.fullName,
      age: farmer.age,
      sex: farmer.sex,
      nationalId: farmer.nationalId,
      farmSize: farmer.farmSize,
      location: farmer.location,
      phone: farmer.phone,
      county: farmer.county,
      constituency: farmer.constituency,
      ward: farmer.ward,
      village: farmer.village,
    })
    openModal()
  }

  const handleDelete = async (farmerId) => {
    try {
      const response = await axios.delete(
        `https://backend.prolocaledge.com/api/farmers/${farmerId}`
      )
      console.log('Farmer deleted:', response.data)

      const updatedResponse = await axios.get(
        `https://backend.prolocaledge.com/api/farmers`
      )
      setFarmerList(updatedResponse.data)
    } catch (error) {
      console.error('An error occurred:', error.message)
    }
  }

  useEffect(() => {
    const fetchFarmerList = async () => {
      try {
        const response = await axios.get(
          `https://backend.prolocaledge.com/api/farmers`
        )
        setFarmerList(response.data)
      } catch (error) {
        console.error('Error fetching farmer list:', error)
      }
    }

    fetchFarmerList()
  }, [])

  return (
    <div className="wakulima-container">
      <h1 style={{ color: 'black' }}>
        <BsPeopleFill className="icon" /> Farmers
      </h1>

      <h2>Farmers</h2>

      <Form.Group controlId="searchTerm">
        <Form.Label style={{ color: 'black' }}>
          Enter Farmer ID or Name:
        </Form.Label>
        <Form.Control
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form.Group>
      <Button variant="primary" onClick={handleSearch}>
        Search
      </Button>

      <Button variant="success" onClick={openModal}>
        Add Farmer
      </Button>

      {foundFarmer && (
        <div className="farmer-info-box">
          <h3>Farmer Details</h3>
          <p>
            <strong>Name:</strong> {foundFarmer.fullName}
          </p>
          <p>
            <strong>Age:</strong> {foundFarmer.age}
          </p>
          <p>
            <strong>Sex:</strong> {foundFarmer.sex}
          </p>
          <p>
            <strong>ID Number:</strong> {foundFarmer.nationalId}
          </p>
          <p>
            <strong>Farm Size:</strong> {foundFarmer.farmSize}
          </p>
          <p>
            <strong>Location:</strong> {foundFarmer.location}
          </p>
          <p>
            <strong>Phone Number:</strong> {foundFarmer.phone}
          </p>
          <p>
            <strong>County:</strong> {foundFarmer.county}
          </p>
          <p>
            <strong>Constituency:</strong> {foundFarmer.constituency}
          </p>
          <p>
            <strong>Ward:</strong> {foundFarmer.ward}
          </p>
          <p>
            <strong>Village:</strong> {foundFarmer.village}
          </p>
        </div>
      )}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Sex</th>
            <th>ID Number</th>
            <th>Farm Size</th>
            <th>Location</th>
            <th>Phone Number</th>
            <th>County</th>
            <th>Constituency</th>
            <th>Ward</th>
            <th>Village</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {farmerList.map((farmer) => (
            <tr key={farmer.id}>
              <td>{farmer.fullName}</td>
              <td>{farmer.age}</td>
              <td>{farmer.sex}</td>
              <td>{farmer.nationalId}</td>
              <td>{farmer.farmSize}</td>
              <td>{farmer.location}</td>
              <td>{farmer.phone}</td>
              <td>{farmer.county}</td>
              <td>{farmer.constituency}</td>
              <td>{farmer.ward}</td>
              <td>{farmer.village}</td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(farmer)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(farmer.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedRow ? 'Edit Farmer' : 'Add New Farmer'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="fullName">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={newFarmer.fullName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            {selectedRow && (
              <Form.Group controlId="fullName">
                <Form.Label> Agent Name:</Form.Label>
                <Form.Control
                  as="select"
                  name="agentId"
                  value={newFarmer.agentId}
                  onChange={handleInputChange}
                  required>
                  {agents?.map((agent) => (
                    <option key={agent.id} value={agent.id}>
                      {agent.fullName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            <Form.Group controlId="age">
              <Form.Label>Age:</Form.Label>
              <Form.Control
                type="text"
                name="age"
                value={newFarmer.age}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="sex">
              <Form.Label>Sex:</Form.Label>
              <Form.Control
                as="select"
                name="sex"
                value={newFarmer.sex}
                onChange={handleInputChange}
                required>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="nationalId">
              <Form.Label>ID Number:</Form.Label>
              <Form.Control
                type="text"
                name="nationalId"
                value={newFarmer.nationalId}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="farmSize">
              <Form.Label>Farm Size:</Form.Label>
              <Form.Control
                type="text"
                name="farmSize"
                value={newFarmer.farmSize}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="location">
              <Form.Label>Location:</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={newFarmer.location}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Phone Number:</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={newFarmer.phone}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="county">
              <Form.Label>County:</Form.Label>
              <Form.Control
                type="text"
                name="county"
                value={newFarmer.county}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="constituency">
              <Form.Label>Constituency:</Form.Label>
              <Form.Control
                type="text"
                name="constituency"
                value={newFarmer.constituency}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="ward">
              <Form.Label>Ward:</Form.Label>
              <Form.Control
                type="text"
                name="ward"
                value={newFarmer.ward}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="village">
              <Form.Label>Village:</Form.Label>
              <Form.Control
                type="text"
                name="village"
                value={newFarmer.village}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              {selectedRow ? 'Update Farmer' : 'Add Farmer'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Wakulima
