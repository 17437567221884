// App.js
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './login'
import Signup from './signup'
import SuperAdminDashboard from './SuperAdminDashboard'
import SupervisorDashboard from './SupervisorDashboard'
// import AgentDashboard from './AgentDashboard'
import FarmerPage from './Farmerpage' // Updated import
import Finance from './Finance'
import FinanceManagement from './components/FinanceManagement'
import Inventory from './Inventory'
import InventoryMain from './components/InventoryMain'
import FarmerModal from './components/AddFarmerModal'
import InputAssignMain from './InputAssignMain'
import InputAssign from './components/InputAssign'

import { AuthProvider } from './context/MyAuthContext'
import Orders from './Orders'
import Agents from './Agents'
import { Visits } from './Visits'

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/superadmindashboard"
            element={<SuperAdminDashboard />}
          />
          <Route
            path="/supervisordashboard"
            element={<SupervisorDashboard />}
          />
          {/* <Route path="/visits/:agentID" element={<AgentDashboard />} /> */}
          <Route path="/farmers" element={<FarmerPage />} />
          <Route path="/agents" element={<Agents />} />
          <Route path="/visits" element={<Visits />} />
          {/* Updated component name */}
          <Route path="/Finance" element={<Finance />} />
          <Route path="/FinanceMangement" element={<FinanceManagement />} />
          <Route path="/Inventory" element={<Inventory />} />
          <Route path="/InventoryMain" element={<InventoryMain />} />
          <Route path="/FarmerModal" element={<FarmerModal />} />
          <Route path="/InputAssignMain" element={<InputAssignMain />} />
          <Route path="/InputAssign" element={<InputAssign />} />
          <Route path="/orders" element={<Orders />} />
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App
