


function signupValidation(values) {
  let errors = {};
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  const namePattern = /^([a-zA-Z]{2,}\s*)+$/;

  if (!values.name || values.name.trim() === "") {
    errors.name = "Name should not be empty";
  } else if (!namePattern.test(values.name)) {
    errors.name = "Invalid name format"; // Adjust the error message accordingly
  } else {
    errors.name = "";
  }

  if (!values.email || values.email.trim() === "") {
    errors.email = "Email should not be empty";
  } else if (!emailPattern.test(values.email)) {
    errors.email = "Invalid email format";
  } else {
    errors.email = "";
  }

  if (!values.password || values.password.trim() === "") {
    errors.password = "Password should not be empty";
  } else if (!passwordPattern.test(values.password)) {
    errors.password =
      "Password must contain at least 8 characters, including at least one letter and one number";
  } else {
    errors.password = "";
  }

  return errors;
}

export default signupValidation;
