import React, { useState } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { Button, Table } from 'react-bootstrap';
import useAPI from './hooks/useAPI';
import AddVisitModal from './components/AddVisitModal';

export const Visits = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [visits, { refetch }] = useAPI('/api/visits');

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleStrigerLevelChange = (id, value) => {
    // Update the striger level for the visit
    const updatedVisits = visits.map(visit => 
      visit.id === id ? { ...visit, strigerLevel: value } : visit
    );
    // This is where you'd likely send the updated data back to your server
  };

  return (
    <div className="grid-container">
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <div className="main-container">
        <h2 style={{ color: 'black' }}>Visits</h2>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Add Visit
        </Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Farmer Name</th>
              <th>Agent Name</th>
              <th>Plant Height</th>
              <th>Foliage Color</th>
              <th>Date of Visit</th>
              <th>Date of First Top Dress</th>
              <th>Date of Second Top Dress</th>
              <th>Striger Level</th> 
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {visits.map((visit) => (
              <tr key={visit.id}>
                <td>{visit.farmer.fullName}</td>
                <td>{visit.agent.fullName}</td>
                <td>{visit.plantHeight}</td>
                <td>{visit.foliageColor}</td>
                <td>{visit.dateOfVisit}</td>
                <td>{visit.dateOfFirsttopdressVisit}</td>
                <td>
                  <select 
                    value={visit.strigerLevel} 
                    onChange={(e) => handleStrigerLevelChange(visit.id, e.target.value)}
                  >
                    <option value="high">High</option>
                    <option value="moderate">Moderate</option>
                    <option value="excess">Excess</option>
                  </select>
                </td>
                <td>{visit.comments}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <AddVisitModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        refetch={refetch}
      />
    </div>
  );
};
