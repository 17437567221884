import React, { useState } from 'react'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import { Button, Table } from 'react-bootstrap'
import useAPI from './hooks/useAPI'
import AddAgentModal from './components/AddAgentModal'
import useCURD from './hooks/useCURD'

const Agents = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { del } = useCURD()

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }
  const handleDelete = (agentID) => {
    del(`/api/agents/${agentID}`)
      .then(() => {
        refetch()
      })
      .catch((err) => console.log('am err: ', err))
  }

  const [agents, { refetch }] = useAPI('/api/agents')
  console.log('agents get: ', agents)

  return (
    <div className="grid-container">
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <div className="main-container">
        <h2 style={{ color: 'black' }}>Agents</h2>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Add Agent
        </Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>ID Number</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>County</th>
              <th>Constituency</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {agents?.map((agent) => (
              <tr key={agent.id}>
                <td>{agent.fullName}</td>
                <td>{agent.age}</td>
                <td>{agent.nationalId}</td>
                <td>{agent.email}</td>
                <td>{agent.phone}</td>
                <td>{agent.county}</td>
                <td>{agent.constituency}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(agent.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <AddAgentModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        refetch={refetch}
      />
    </div>
  )
}

export default Agents
