// Signup.js

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import validation from './signupvalidation'
import './login.css'

function Signup() {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',

    role: '',
  })

  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const navigate = useNavigate()

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }))
    setTouched((prev) => ({ ...prev, [event.target.name]: true }))
  }

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev)
  }

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validation(values, touched))
    console.log(values)
    if (!errors.name && !errors.email && !errors.password && !errors.role) {
      axios
        .post(`https://backend.prolocaledge.com/auth/register`, values)
        .then((res) => {
          if (res.data === 'success') {
            // Redirect to the login page after successful signup
            navigate('/login')
          } else {
            alert('Error during signup')
          }
        })
        .catch((err) => {
          console.error('Error during API call:', err)
          // Handle the error, for example, show an error message to the user
        })
    }
  }

  return (
    <div className="login-container">
      <div className="header">
        <img src="./images/logo.png" alt="Organization Logo" className="logo" />
        <div className="organization-name">Pro-Local Edge</div>
      </div>
      <div className="login-form">
        <form action="" onSubmit={handleSubmit}>
          <h2>Sign-Up</h2>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name:
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter Name"
              name="name"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {touched.name && errors.name && (
              <span className="text-danger">{errors.name}</span>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email:
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {touched.email && errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password:
            </label>
            <div className="input-group">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Enter Password"
                name="password"
                onChange={handleInput}
                className="form-control rounded-0"
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={handleTogglePassword}>
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            {touched.password && errors.password && (
              <span className="text-danger">{errors.password}</span>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password:
            </label>
            <div className="input-group">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                placeholder="Confirm Password"
                name="confirmPassword"
                onChange={handleInput}
                className="form-control rounded-0"
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={handleToggleConfirmPassword}>
                {showConfirmPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            {touched.confirmPassword && errors.confirmPassword && (
              <span className="text-danger">{errors.confirmPassword}</span>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="role" className="form-label">
              Role:
            </label>
            <select
              id="role"
              name="role"
              onChange={handleInput}
              className="form-control rounded-0">
              <option value="">Select Role</option>
              <option value="SuperAdmin">Super Admin</option>
              <option value="Supervisor">Supervisor</option>
              <option value="FarmerAgent">Farmer Agent</option>
            </select>
            {touched.role && errors.role && (
              <span className="text-danger">{errors.role}</span>
            )}
          </div>
          <button type="submit" className="btn btn-success w-100 rounded-100">
            Sign up
          </button>
          <p>You agree to our terms and policies.</p>
          <Link
            to="/"
            className="btn btn-default border w-100 bg-light rounded-0 text-decoration-none">
            Already have an account? Login
          </Link>
        </form>
      </div>
    </div>
  )
}

export default Signup
