import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-modal'

const SupervisorDashboard = () => {
  // County and Constituency options
  const counties = ['Siaya', 'Kakamega']
  const constituencies = {
    Siaya: ['Gem', 'Alego Usonga', 'Ugenya'],
    Kakamega: ['Mumias'],
  }

  const [agentData, setAgentData] = useState({
    fullName: '',
    email: '',
    age: '',
    nationalId: '',
    phone: '',
    county: '',
    constituency: '',
  })

  const [farmers, setFarmers] = useState([])
  const [agents, setAgents] = useState([])
  const [selectedFarmer, setSelectedFarmer] = useState('')
  const [visitDate, setVisitDate] = useState('')
  const [plantHeight, setPlantHeight] = useState('')
  const [foliageColor, setFoliageColor] = useState('')
  const [comment, setComment] = useState('')
  const [assignedFarmers, setAssignedFarmers] = useState({})
  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false)
  const [isVisitModalOpen, setIsVisitModalOpen] = useState(false)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [selectedAgent, setSelectedAgent] = useState('')

  useEffect(() => {
    // Fetch the list of farmers from the API when component loads
    const fetchFarmers = async () => {
      try {
        const response = await axios.get(
          `https://backend.prolocaledge.com/api/farmers`
        )
        setFarmers(response.data)
      } catch (error) {
        console.error('Error fetching farmers:', error)
      }
    }
    fetchFarmers()
  }, [])

  // Fetch the list of agents from the API when component loads
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(
          `https://backend.prolocaledge.com/api/agents`
        )
        setAgents(response.data)
      } catch (error) {
        console.error('Error fetching agents:', error)
      }
    }
    fetchAgents()
  }, [])

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setAgentData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Handle agent form submission
  const handleAgentSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        `https://backend.prolocaledge.com/api/agents`,
        agentData
      )
      console.log('Agent created successfully:', response.data)
      // Add new agent to the list
      setAgents((prevAgents) => [...prevAgents, response.data])
      // Clear form after successful submission
      setAgentData({
        fullName: '',
        age: '',
        nationalId: '',
        phone: '',
        county: '',
        constituency: '',
      })
      closeAgentModal()
    } catch (error) {
      console.error('Error creating agent:', error)
    }
  }

  // Handle assigning farmers to agents
  const handleAssignFarmer = async () => {
    try {
      const response = await axios.post(
        `https://backend.prolocaledge.com/api/assign-farmer`,
        {
          agentId: selectedAgent,
          farmerId: selectedFarmer,
        }
      )
      console.log('Farmer assigned successfully:', response.data)
      setAssignedFarmers((prev) => ({
        ...prev,
        [selectedAgent]: [...(prev[selectedAgent] || []), selectedFarmer],
      }))
      setSelectedFarmer('')
      setSelectedAgent('')
      closeAssignModal()
    } catch (error) {
      console.error('Error assigning farmer:', error)
    }
  }

  // Handle sending comment to the agent
  //   const handleSendComment = async () => {
  //     try {
  //       const response = await axios.post(
  //         `https://backend.prolocaledge.com/api/send-comment`,
  //         {
  //           agentId: selectedAgent,
  //           comment,
  //         }
  //       )
  //       console.log('Comment sent successfully:', response.data)
  //       setComment('') // Clear comment field after sending
  //     } catch (error) {
  //       console.error('Error sending comment:', error)
  //     }
  //   }

  // Handle opening and closing modals
  const openAgentModal = () => setIsAgentModalOpen(true)
  const closeAgentModal = () => setIsAgentModalOpen(false)
  const openVisitModal = () => setIsVisitModalOpen(true)
  const closeVisitModal = () => setIsVisitModalOpen(false)
  const openAssignModal = () => setIsAssignModalOpen(true)
  const closeAssignModal = () => setIsAssignModalOpen(false)

  return (
    <div className="supervisor-container">
      <h1>Supervisor Dashboard</h1>
      <button onClick={openAgentModal}>Add New Agent</button>
      <div className="spacer" />
      <div className="button-group">
        <button onClick={openVisitModal}>Record Farm Visit</button>
        <button onClick={openAssignModal}>Assign Farmers to Agents</button>
      </div>

      {/* Agent Modal */}
      <Modal
        isOpen={isAgentModalOpen}
        onRequestClose={closeAgentModal}
        contentLabel="Agent Modal"
        className="custom-modal"
        overlayClassName="custom-overlay">
        <h3>Add New Agent</h3>
        <form onSubmit={handleAgentSubmit}>
          <label>
            Full Name:
            <input
              type="text"
              name="fullName"
              value={agentData.fullName}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={agentData.email}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Age:
            <input
              type="number"
              name="age"
              value={agentData.age}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            National ID:
            <input
              type="text"
              name="nationalId"
              value={agentData.nationalId}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Phone Number:
            <input
              type="text"
              name="phone"
              value={agentData.phone}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            County:
            <select
              name="county"
              value={agentData.county}
              onChange={handleInputChange}
              required>
              <option value="">Select a county</option>
              {counties.map((county) => (
                <option key={county} value={county}>
                  {county}
                </option>
              ))}
            </select>
          </label>
          <label>
            Constituency:
            <select
              name="constituency"
              value={agentData.constituency}
              onChange={handleInputChange}
              required
              disabled={!agentData.county}>
              <option value="">Select a constituency</option>
              {agentData.county &&
                constituencies[agentData.county].map((constituency) => (
                  <option key={constituency} value={constituency}>
                    {constituency}
                  </option>
                ))}
            </select>
          </label>
          <button type="submit">Save Agent</button>
          <button type="button" onClick={closeAgentModal}>
            Cancel
          </button>
        </form>
      </Modal>

      {/* Farm Visit Modal */}
      <Modal
        isOpen={isVisitModalOpen}
        onRequestClose={closeVisitModal}
        contentLabel="Visit Modal"
        className="custom-modal"
        overlayClassName="custom-overlay">
        <h3>Record Farm Visit</h3>
        <label>
          Select Farmer:
          <select
            value={selectedFarmer}
            onChange={(e) => setSelectedFarmer(e.target.value)}
            required>
            <option value="">Select a farmer</option>
            {farmers.map((farmer) => (
              <option key={farmer.id} value={farmer.id}>
                {farmer.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Date of Visit:
          <input
            type="date"
            value={visitDate}
            onChange={(e) => setVisitDate(e.target.value)}
            required
          />
        </label>
        <label>
          Plant Height (cm):
          <input
            type="number"
            value={plantHeight}
            onChange={(e) => setPlantHeight(e.target.value)}
            required
          />
        </label>
        <label>
          Foliage Color:
          <input
            type="text"
            value={foliageColor}
            onChange={(e) => setFoliageColor(e.target.value)}
            required
          />
        </label>
        <label>
          Comments:
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </label>

        <button type="button" onClick={closeVisitModal}>
          Cancel
        </button>
      </Modal>

      {/* Assign Farmers Modal */}
      <Modal
        isOpen={isAssignModalOpen}
        onRequestClose={closeAssignModal}
        contentLabel="Assign Modal"
        className="custom-modal"
        overlayClassName="custom-overlay">
        <h3>Assign Farmer to Agent</h3>
        <label>
          Select Agent:
          <select
            value={selectedAgent}
            onChange={(e) => setSelectedAgent(e.target.value)}
            required>
            <option value="">Select an agent</option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.fullName}
              </option>
            ))}
          </select>
        </label>
        <label>
          Select Farmer:
          <select
            value={selectedFarmer}
            onChange={(e) => setSelectedFarmer(e.target.value)}
            required>
            <option value="">Select a farmer</option>
            {farmers.map((farmer) => (
              <option key={farmer.id} value={farmer.id}>
                {farmer.name}
              </option>
            ))}
          </select>
        </label>
        <button type="button" onClick={handleAssignFarmer}>
          Assign Farmer
        </button>
        <button type="button" onClick={closeAssignModal}>
          Cancel
        </button>
      </Modal>

      {/* Compare Performance Section */}
      <div className="compare-performance">
        <h2>Compare Farmer Performance Per Agent</h2>
        <table className="comparison-table">
          <thead>
            <tr>
              <th>Agent Name</th>
              <th>Farmer Name</th>
              <th>Performance Metric</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(assignedFarmers).map((agentId) =>
              assignedFarmers[agentId].map((farmerId, index) => (
                <tr key={`${agentId}-${farmerId}`}>
                  <td>
                    {agents.find((agent) => agent.id === agentId)?.fullName}
                  </td>
                  <td>
                    {farmers.find((farmer) => farmer.id === farmerId)?.name}
                  </td>
                  <td>Performance Data Here</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Embedded CSS */}
      <style jsx>{`
        .supervisor-container {
          padding: 20px;
        }

        h1,
        h2 {
          color: #333;
          margin-bottom: 20px;
        }

        button {
          background-color: #4caf50;
          color: white;
          padding: 10px 20px;
          margin: 10px 0;
          border: none;
          cursor: pointer;
          border-radius: 5px;
        }

        button:hover {
          background-color: #45a049;
        }

        .spacer {
          margin: 10px 0;
        }

        .button-group {
          display: flex;
          gap: 20px; /* Adjust this value for desired spacing */
          margin-top: 20px;
        }

        .custom-modal {
          background-color: white;
          padding: 20px;
          max-width: 500px;
          margin: auto;
          border-radius: 10px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }

        .custom-overlay {
          background-color: rgba(0, 0, 0, 0.75);
        }

        label {
          display: block;
          margin-bottom: 10px;
        }

        input,
        select,
        textarea {
          width: 100%;
          padding: 8px;
          margin: 5px 0;
          box-sizing: border-box;
        }

        textarea {
          height: 100px;
        }

        .agents-tab {
          margin-top: 40px;
        }

        .agents-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        .agents-table th,
        .agents-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        .agents-table th {
          background-color: #f2f2f2;
        }

        .performance-reports,
        .assign-farmers,
        .compare-performance {
          margin-top: 40px;
        }

        .comparison-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        .comparison-table th,
        .comparison-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        .comparison-table th {
          background-color: #f2f2f2;
        }
      `}</style>
    </div>
  )
}

export default SupervisorDashboard
