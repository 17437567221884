// Sidebar.jsx
import React from 'react'
import { Link } from 'react-router-dom'
import {
  BsGrid1X2Fill,
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsListCheck,
  BsMenuButtonWideFill,
  BsFillGearFill,
} from 'react-icons/bs'
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2'

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const userRole = localStorage.getItem('userRole')

  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? 'sidebar-responsive' : ''}>
      <div className="sidebar-title">
        <div className="sidebar-brand" style={{ color: 'gold' }}>
          PRO-LOCAL EDGE
        </div>

        <span className="icon close_icon" onClick={OpenSidebar}>
          X
        </span>
      </div>

      <ul className="sidebar-list">
        <li className="sidebar-list-item">
          <Link to="/visits">
            <BsPeopleFill className="icon" /> Visits
          </Link>
        </li>
        {userRole !== 'agent' && (
          <>
            <li className="sidebar-list-item">
              <Link to="/dashboard">
                <BsGrid1X2Fill className="icon" /> Dashboard
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/InputAssignMain">
                <BsFillArchiveFill className="icon" /> Inputs Assigned
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/Finance">
                <BsFillGrid3X3GapFill className="icon" /> Finance
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/SupervisorDashboard">
                <BsPeopleFill className="icon" /> Supervisor
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/farmers">
                <BsPeopleFill className="icon" /> Farmers
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/agents">
                <BsPeopleFill className="icon" /> Agents
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/orders">
                <HiOutlineClipboardDocumentList className="icon" /> Orders
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/Inventory">
                <BsListCheck className="icon" /> Inventory
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/reports">
                <BsMenuButtonWideFill className="icon" /> Reports
              </Link>
            </li>
            <li className="sidebar-list-item">
              <Link to="/settings">
                <BsFillGearFill className="icon" /> Setting
              </Link>
            </li>
          </>
        )}
      </ul>
    </aside>
  )
}

export default Sidebar
