import Axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'

// axios interceptors for cookies
const instance = Axios.create({
  withCredentials: true,
  baseURL: 'https://backend.prolocaledge.com',
})

const axios = setupCache(instance, {})

export const catchAxiosError = (error) => {
  if (Axios.isCancel(error)) {
    return new Error('request cancelled')
  }

  if (error.response) {
    return new Error(
      error.response.data?.message ||
        error.response.data ||
        'Something went wrong'
    )
  }

  if (error.request) {
    return new Error('Something went wrong')
  }

  return error
}

export default axios
