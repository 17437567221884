import React, { useState } from 'react'
import './wakulima.css' // Import shared styles
import { BsListCheck } from 'react-icons/bs'
import Modal from 'react-modal'
// import { saveAs } from 'file-saver';
import axios from 'axios'
import useAPI from '../hooks/useAPI'
import useCURD from '../hooks/useCURD'
import { formatName } from '../app/utils'

const InventoryMain = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [inventories, { refetch }] = useAPI('/api/inventories')
  const [farmers] = useAPI('/api/farmers')
  const [inventory] = useAPI('/api/inventories')
  const { post, put, del } = useCURD()

  console.log('farmers: ', farmers)
  console.log('inventory: ', inventory)

  const options = [
    { value: 'fertilizer', text: 'Fertilizer' },
    { value: 'herbiscide', text: 'Herbicide' },
    { value: 'seed', text: 'Seed' },
  ]
  const fertilizerTypes = [
    { value: 'top dress', text: 'Top Dress' },
    { value: 'planting', text: 'Planting' },
  ]
  const weightTypes = [
    { value: 'litres', text: 'Litres' },
    { value: 'millilitres', text: 'Millilitres' },
    { value: 'kg', text: 'Kilogram' },
  ]
  const [selected, setSelected] = useState()
  const [modalInput, setModalInput] = useState({
    name: '',
    brand: '',
    variery: '',
    quantity: '',
    inventoryType: '',
    batchNo: '',
    dom: '',
    noOfItems: '',
    price: '',
    weight: '',
    typeOfFertilizer: '',
  })
  const [assign, setAssign] = useState({
    farmerId: '',
    productId: '',
    numberOfItems: '',
  })
  const [searchTerm, setSearchTerm] = useState('')
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const open = () => setModalOpen(true)
  const close = () => setModalOpen(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [foundInventory, setFoundInventory] = useState(null)

  const handleEdit = (inventory) => {
    setSelectedRow(inventory)
    setModalInput({
      name: inventory?.name,
      brand: inventory?.brand,
      variery: inventory?.variery,
      quantity: inventory?.quantity,
      inventoryType: inventory?.inventoryType,
      batchNo: inventory?.batchNo,
      dom: inventory?.dom,
      noOfItems: inventory?.noOfItems,
      price: inventory?.price,
      weight: inventory?.weight,
      typeOfFertilizer: inventory?.typeOfFertilizer,
    })
    openModal()
  }

  const handleSearch = async () => {
    try {
      let response = await axios.get(
        `https://backend.prolocaledge.comapi/inventories/name/${searchTerm}`
      )

      return setFoundInventory(response) // trigger modal to display
    } catch (error) {
      console.error('Error searching for farmer:', error)
    }
  }

  const handleDelete = async (inventory) => {
    del(`/api/inventories/${inventory}`)
      .then(() => {
        refetch()
      })
      .catch(() => console('Error deleting record'))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (selectedRow) {
      put(`/api/inventories/${selectedRow.id}`, modalInput)
        .then(() => {
          refetch()
          closeModal()
        })
        .catch(() => console.log('Error updating inventory'))
    } else {
      post('/api/inventories', modalInput)
        .then(() => {
          refetch()
          closeModal()
        })
        .catch(() => console.log('Error creating inventory'))
    }
  }

  const onAssign = async (e) => {
    e.preventDefault()
    post('/api/orders', assign)
      .then(() => {
        refetch()
        close()
      })
      .catch(() => console.log('Error Assigning inventory'))
  }

  const handleChange = (event) => {
    const selectedType = event.target.value
    setSelected(selectedType)
    setModalInput((prevInput) => ({
      ...prevInput,
      inventoryType: selectedType,
    }))
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setModalInput((prevInput) => ({
      ...prevInput,
      [name]: value,
    }))
  }

  return (
    <div className="wakulima-container">
      <h1 style={{ color: 'black' }}>
        <BsListCheck className="icon" /> Inventory Management
      </h1>

      <div className="d-flex flex-row justify-content-evenly py-4 gap-4">
        <input
          type="text"
          placeholder="Enter item name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSearch}>
          Search
        </button>
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <button onClick={() => openModal()}>Add Inventory</button>
      </div>

      {/* Modal for Adding Inputs */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Inventory Item Modal"
        className="custom-modal"
        overlayClassName="custom-overlay">
        <h3>Add New Inventory Item</h3>
        <form>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={modalInput.name}
            onChange={handleInputChange}
          />

          <label>Brand:</label>
          <input
            type="text"
            name="brand"
            value={modalInput.brand}
            onChange={handleInputChange}
          />

          <label>Date of Manufacture:</label>
          <input
            type="date"
            name="dom"
            value={modalInput.dom}
            onChange={handleInputChange}
          />

          <label>Inventory Type: </label>
          <select value={selected} onChange={handleChange}>
            <option value="">Choose...</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>

          {selected === 'fertilizer' && (
            <>
              <label>Type of Fertilizer: </label>
              <select
                value={modalInput.typeOfFertilizer}
                onChange={(e) =>
                  setModalInput((prevInput) => ({
                    ...prevInput,
                    typeOfFertilizer: e.target.value,
                  }))
                }>
                <option value="">Choose...</option>
                {fertilizerTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </>
          )}

          <label>{selected === 'seed' ? 'Batch No:' : ''} </label>
          <input
            type={selected === 'seed' ? 'text' : 'hidden'}
            name="batchNo"
            value={modalInput.batchNo}
            onChange={handleInputChange}
          />

          <label>{selected === 'seed' ? 'Variety:' : ''}</label>
          <input
            type={selected === 'seed' ? 'text' : 'hidden'}
            name="variery"
            value={modalInput.variery}
            onChange={handleInputChange}
          />

          <label>Weight Type: </label>
          <select
            value={modalInput.weight}
            onChange={(e) =>
              setModalInput((prevInput) => ({
                ...prevInput,
                weight: e.target.value,
              }))
            }>
            <option value="">Choose...</option>
            {weightTypes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>

          <label>Quantity:</label>
          <input
            type="number"
            name="quantity"
            value={modalInput.quantity}
            onChange={handleInputChange}
          />
          <label>Number of Items:</label>
          <input
            type="number"
            name="noOfItems"
            value={modalInput.noOfItems}
            onChange={handleInputChange}
          />
          <label>Price:</label>
          <input
            type="number"
            name="price"
            value={modalInput.price}
            onChange={handleInputChange}
          />
          <div className="btn-farmer">
            <button type="button" onClick={closeModal}>
              Cancel
            </button>
            <button type="button" onClick={onSubmit}>
              {selectedRow ? 'Edit Inventory' : 'Add to Inventory'}
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={modalOpen}
        onRequestClose={close}
        contentLabel="Add Inventory Item Modal"
        className="custom-modal"
        overlayClassName="custom-overlay">
        {/* Display farmer assigned detail correct this modal for assigning farmer detail */}
        <h3>Assign Farmer Inventory</h3>
        <form>
          <label>Farmer Name:</label>
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) =>
              setAssign((prevInput) => ({
                ...prevInput,
                farmerId: e.target.value,
              }))
            }>
            <option selected>Select Farmer</option>
            {farmers?.map((c) => (
              <option key={c.id} value={c.id}>
                {c.fullName}
              </option>
            ))}
          </select>

          <label>Product name:</label>
          <select
            class="form-select"
            aria-label="Default select example"
            onChange={(e) =>
              setAssign((prevInput) => ({
                ...prevInput,
                productId: e.target.value,
              }))
            }>
            <option selected>Select Product</option>
            {inventory?.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>

          <label>No of Items:</label>
          <input
            type="number"
            name="numberOfItems"
            value={assign.numberOfItems}
            onChange={(e) =>
              setAssign((prevInput) => ({
                ...prevInput,
                numberOfItems: e.target.value,
              }))
            }
          />

          <div className="btn-farmer">
            <button type="button" onClick={close}>
              Cancel
            </button>
            <button type="button" onClick={onAssign}>
              Assign
            </button>
          </div>
        </form>
      </Modal>
      {foundInventory ? (
        <div>
          <h3>Inventory Details</h3>
          {/* Display found farmer details */}
          {/* ... (same as in your existing code) */}
        </div>
      ) : (
        <p>No farmer found with the provided ID or name.</p>
      )}
      {/* Display Inventory Table */}
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Brand</th>
            <th>Inventory Type</th>
            <th>Date of Manufacture</th>
            <th>Type of Fertilizer</th>
            <th>Variety</th>
            <th>Batch No</th>
            <th>Quantity</th>
            <th>No of Items</th>
            <th>Unit Price</th>
            <th>Total Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {inventories.length !== 0 ? (
            inventories.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.brand}</td>
                <td>{item.inventoryType}</td>
                <td>{item.dom}</td>
                <td>{item.typeOfFertilizer}</td>
                <td>{item.variery}</td>
                <td>{item.batchNo}</td>
                <td>{formatName(item.quantity, item.weight)}</td>
                <td>{item.noOfItems}</td>
                <td>{item.price}</td>
                <td>{item.totalPrice}</td>
                <td className="d-flex">
                  <button onClick={() => handleEdit(item)}>Edit</button>
                  <button onClick={() => open()}>Assign Farmer</button>
                  <button onClick={() => handleDelete(item.id)}>Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>No data Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default InventoryMain
