import React, { useState } from 'react'
import './login.css'
import { useNavigate } from 'react-router-dom'
import validation from './loginvalidation'
import axios from 'axios'

function Login() {
  const [values, setValues] = useState({
    email: '',
    password: '',
    role: '',
  })

  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [showPassword, setShowPassword] = useState(false) // Define showPassword state
  const navigate = useNavigate()

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }))
    setTouched((prev) => ({ ...prev, [event.target.name]: true }))
  }

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev)
  }

  const handleRoleNavigation = (role, username) => {
    switch (role) {
      case 'SuperAdmin':
        navigate('/SuperAdminDashboard', { state: { username } })
        break
      case 'Supervisor':
        navigate('/SupervisorDashboard')
        break
      case 'FarmerAgent':
        navigate('/AgentDashboard')
        break
      case 'agent':
        navigate('/visits')
        break
      default:
        // Handle any other roles or navigate to a default dashboard
        navigate('/default-dashboard')
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrors(validation(values, touched))

    if (!errors.email && !errors.password) {
      try {
        const res = await axios.post(
          `https://backend.prolocaledge.com/auth/login`,
          values
        )

        if (res.data) {
          // Assuming the response contains user role and username
          const { role, username } = res.data

          // Save user role and other data to local storage
          localStorage.setItem('userRole', role)
          localStorage.setItem('username', username)

          // Navigate based on the user's role
          handleRoleNavigation(role, username)
        } else {
          setErrors({ general: 'No record exists' })
        }
      } catch (err) {
        console.error('Error during API call:', err)
        // Handle the error, for example, show an error message to the user
        setErrors({ general: 'An error occurred during login' })
      }
    }
  }

  return (
    <div className="login-container">
      <div className="header">
        <img src="./images/logo.png" alt="Organization Logo" className="logo" />
        <div className="organization-name">Pro-Local Edge</div>
      </div>
      <div className="login-form">
        <form action="" onSubmit={handleSubmit}>
          <h2>Login</h2>
          {errors.general && (
            <span className="text-danger">{errors.general}</span>
          )}
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email:
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {touched.email && errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password:
            </label>
            <div className="input-group">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Enter Password"
                name="password"
                onChange={handleInput}
                className="form-control rounded-0"
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={handleTogglePassword}>
                {showPassword ? 'Hide' : 'Show'}
              </button>
              {touched.password && errors.password && (
                <span className="text-danger">{errors.password}</span>
              )}
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="role" className="form-label">
              Role:
            </label>
            <select
              id="role"
              name="role"
              onChange={handleInput}
              className="form-control rounded-0">
              <option value="">Select Role</option>
              <option value="SuperAdmin">Super Admin</option>
              <option value="Supervisor">Supervisor</option>
              <option value="FarmerAgent">Farmer Agent</option>
            </select>
            {touched.role && errors.role && (
              <span className="text-danger">{errors.role}</span>
            )}
          </div>
          <button type="submit" className="btn btn-success w-100 rounded-100">
            Login
          </button>
          <p>You agree to our terms and policies.</p>
          {/* <Link
            to="/signup"
            className="btn btn-default border w-100 bg-light rounded-0 text-decoration-none">
            Create Account
          </Link> */}
        </form>
      </div>
    </div>
  )
}

export default Login
