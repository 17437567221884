import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import useAPI from '../hooks/useAPI'
import useCURD from '../hooks/useCURD'

const AddVisitModal = ({ show, handleClose, refetch }) => {
  const [formData, setFormData] = useState({
    farmerId: '',
    agentId: '',
    plantHeight: '',
    foliageColor: '',
    dateOfVisit: '',
    dateOfFirstTopDress: '',
    dateOfSecondTopDress: '',
    strigerLevel: 'high',
    comments: ''
  })

  const { post } = useCURD()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    post('/api/visits', formData)
      .then(() => {
        refetch()
        handleClose()
      })
      .catch((err) => console.log('Error creating visit', err))
  }

  const [farmers] = useAPI('/api/farmers')
  const [agents] = useAPI('/api/agents')

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Visit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Farmer Name</Form.Label>
            <Form.Control
              as="select"
              name="farmerId"
              value={formData.farmerId}
              onChange={handleChange}
              required>
              <option value="">Select Farmer</option>
              {farmers.map((farmer) => (
                <option key={farmer.id} value={farmer.id}>
                  {farmer.fullName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Agent Name</Form.Label>
            <Form.Control
              as="select"
              name="agentId"
              value={formData.agentId}
              onChange={handleChange}
              required>
              <option value="">Select Agent</option>
              {agents.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.fullName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Plant Height (cm)</Form.Label>
            <Form.Control
              type="number"
              name="plantHeight"
              value={formData.plantHeight}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Foliage Color</Form.Label>
            <Form.Control
              type="text"
              name="foliageColor"
              value={formData.foliageColor}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Date of Visit</Form.Label>
            <Form.Control
              type="date"
              name="dateOfVisit"
              value={formData.dateOfVisit}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Date of First Top Dress</Form.Label>
            <Form.Control
              type="date"
              name="dateOfFirstTopDress"
              value={formData.dateOfFirstTopDress}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Date of Second Top Dress</Form.Label>
            <Form.Control
              type="date"
              name="dateOfSecondTopDress"
              value={formData.dateOfSecondTopDress}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Striger Level</Form.Label>
            <Form.Control
              as="select"
              name="strigerLevel"
              value={formData.strigerLevel}
              onChange={handleChange}
              required>
              <option value="high">High</option>
              <option value="moderate">Moderate</option>
              <option value="excess">Excess</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Save Visit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddVisitModal
