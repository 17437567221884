import React from 'react';
import './wakulima.css'; // Import shared styles
import { BsFillGrid3X3GapFill } from 'react-icons/bs';

const FinanceManagement = () => {
  const handleQuickBooksLink = () => {
    window.location.href = 'https://accounts.intuit.com/app/sign-in?app_group=QBO&asset_alias=Intuit.accounting.core.qbowebapp&locale=en-ROW&state=%7B%22queryParams%22%3A%7B%22locale%22%3A%22en-ROW%22%7D%7D&app_environment=prod';
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#0070c9',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'block'  // Ensures button appears as a block element
  };

  return (
    <div className='wakulima-container'>
      <h1 style={{ color: 'black' }}>
        <BsFillGrid3X3GapFill className='icon' /> Finance Management
      </h1>

      {/* QuickBooks Link Button */}
      <button 
        style={buttonStyle}
        onClick={handleQuickBooksLink}
      >
        Go to QuickBooks
      </button>

      {/* New Button for the specified QuickBooks URL */}
      <button 
        style={buttonStyle}
        onClick={() => window.open('https://accounts.intuit.com/app/sign-in?app_group=QBO&asset_alias=Intuit.accounting.core.qbowebapp&locale=en-ROW&state=%7B%22queryParams%22%3A%7B%22locale%22%3A%22en-ROW%22%7D%7D&app_environment=prod', '_blank')}
      >
        New QuickBooks Link
      </button>
    </div>
  );
}

export default FinanceManagement;

