import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-modal'

const InputAssign = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [foundFarmer, setFoundFarmer] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [inventoryList, setInventoryList] = useState([])
  const [assignedInventories, setAssignedInventories] = useState([])
  const [isFarmerModalOpen, setIsFarmerModalOpen] = useState(false)
  const [isInventoryModalOpen, setIsInventoryModalOpen] = useState(false)

  const options = [
    { value: 'male', text: 'Male' },
    { value: 'female', text: 'Female' },
  ]

  const [selected, setSelected] = useState(options[0].value)

  const [newFarmer, setNewFarmer] = useState({
    fullName: '',
    age: '',
    sex: selected,
    nationalId: '',
    farmSize: '',
    location: '',
    phone: '',
    county: '',
    constituency: '',
    ward: '',
    village: '',
  })

  const [modalInput, setModalInput] = useState({
    name: '',
    brand: '',
    variety: '',
    quantity: '',
    type: '',
  })

  const openFarmerModal = () => setIsFarmerModalOpen(true)
  const closeFarmerModal = () => setIsFarmerModalOpen(false)

  const openInventoryModal = () => setIsInventoryModalOpen(true)
  const closeInventoryModal = () => setIsInventoryModalOpen(false)

  const handleChange = (event) => {
    setSelected(event.target.value)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewFarmer((prevFarmer) => ({
      ...prevFarmer,
      [name]: value,
    }))
  }

  const handleSearch = async () => {
    try {
      let response
      if (!isNaN(searchTerm)) {
        response = await axios.get(
          `https://backend.prolocaledge.com/api/farmers/nationalId/${searchTerm}`
        )
      } else {
        response = await axios.get(
          `https://backend.prolocaledge.com/api/farmers/name/${searchTerm}`
        )
      }

      const farmer = response.data[0]
      setFoundFarmer(farmer)
    } catch (error) {
      console.error('Error searching for farmer:', error)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (selectedRow) {
      // Edit existing farmer
      await axios.put(
        `https://backend.prolocaledge.com/api/farmers/${selectedRow.id}`,
        newFarmer
      )
    } else {
      // Add new farmer
      await axios.post(
        `https://backend.prolocaledge.com/api/farmers`,
        newFarmer
      )
    }
    closeFarmerModal()
    setAssignedInventories([])
  }

  const handleEdit = (farmer) => {
    setSelectedRow(farmer)
    setNewFarmer({
      fullName: farmer.fullName,
      age: farmer.age,
      sex: farmer.sex,
      nationalId: farmer.nationalId,
      farmSize: farmer.farmSize,
      location: farmer.location,
      phone: farmer.phone,
      county: farmer.county,
      constituency: farmer.constituency,
      ward: farmer.ward,
      village: farmer.village,
    })
    openFarmerModal()
  }

  const handleDelete = async (farmerId) => {
    await axios.delete(
      `https://backend.prolocaledge.com/api/farmers/${farmerId}`
    )
    setAssignedInventories([])
  }

  const handleAssignInventory = async (e) => {
    e.preventDefault()
    const assignedInventory = {
      ...modalInput,
      farmerId: foundFarmer.id,
    }
    await axios.post(
      `https://backend.prolocaledge.com/api/assignments`,
      assignedInventory
    )
    setAssignedInventories((prev) => [...prev, assignedInventory])
    closeInventoryModal()
  }

  useEffect(() => {
    const fetchInventoryList = async () => {
      try {
        const response = await axios.get(
          `https://backend.prolocaledge.com/api/inventories`
        )
        setInventoryList(response.data)
      } catch (error) {
        console.error('Error fetching inventory list:', error)
      }
    }

    fetchInventoryList()
  }, [])

  useEffect(() => {
    if (selectedRow) {
      // Fetch assigned inventories for the selected farmer
    }
  }, [selectedRow])

  return (
    <div className="wakulima-container">
      <h6>Input Assign</h6> {/* Header */}
      <h2>Farmers</h2>
      <label>
        Enter Farmer ID or Name:
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </label>
      <button onClick={handleSearch}>Search</button>
      {foundFarmer && (
        <div>
          <h3>Found Farmer</h3>
          <p>{foundFarmer.fullName}</p>
          <button onClick={() => handleEdit(foundFarmer)}>Edit Farmer</button>
        </div>
      )}
      <h2>Farmer List</h2>
      {/* Farmer List Table */}
      {/* Replace the following with your existing farmer list rendering code */}
      {/* ... */}
      <Modal
        isOpen={isFarmerModalOpen}
        onRequestClose={closeFarmerModal}
        contentLabel="Farmer Modal"
        className="custom-modal"
        overlayClassName="custom-overlay">
        <h3>{selectedRow ? 'Edit Farmer' : 'Add New Farmer'}</h3>
        <form onSubmit={onSubmit}>
          <label>
            Full Name:
            <input
              type="text"
              name="fullName"
              value={newFarmer.fullName}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Age:
            <input
              type="text"
              name="age"
              value={newFarmer.age}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Sex:
            <select
              name="sex"
              value={newFarmer.sex}
              onChange={handleInputChange}>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </label>
          {/* Add other farmer details input fields */}
          <button type="submit">Save Farmer</button>
          <button type="button" onClick={closeFarmerModal}>
            Cancel
          </button>
        </form>
      </Modal>
      <h2>Assign Inventory</h2>
      <button onClick={openInventoryModal}>Assign Inventory</button>
      <h2>Assigned Inventory List</h2>
      <ul>
        {assignedInventories.map((inventory, index) => (
          <li key={index}>
            {inventory.name} - Type: {inventory.type}
          </li>
        ))}
      </ul>
      <h2>Inventory List</h2>
      {/* Inventory List Table */}
      {/* Replace the following with your existing inventory list rendering code */}
      {/* ... */}
      <Modal
        isOpen={isInventoryModalOpen}
        onRequestClose={closeInventoryModal}
        contentLabel="Assign Inventory Modal"
        className="custom-modal"
        overlayClassName="custom-overlay">
        <h3>Assign Inventory</h3>
        <form onSubmit={handleAssignInventory}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={modalInput.name}
              onChange={(e) =>
                setModalInput({ ...modalInput, name: e.target.value })
              }
            />
          </label>
          <label>
            Brand:
            <input
              type="text"
              name="brand"
              value={modalInput.brand}
              onChange={(e) =>
                setModalInput({ ...modalInput, brand: e.target.value })
              }
            />
          </label>
          <label>
            Variety:
            <input
              type="text"
              name="variety"
              value={modalInput.variety}
              onChange={(e) =>
                setModalInput({ ...modalInput, variety: e.target.value })
              }
            />
          </label>
          <label>
            Quantity:
            <input
              type="number"
              name="quantity"
              value={modalInput.quantity}
              onChange={(e) =>
                setModalInput({ ...modalInput, quantity: e.target.value })
              }
            />
          </label>
          <label>
            Type:
            <input
              type="text"
              name="type"
              value={modalInput.type}
              onChange={(e) =>
                setModalInput({ ...modalInput, type: e.target.value })
              }
            />
          </label>
          <button type="submit">Assign</button>
          <button type="button" onClick={closeInventoryModal}>
            Close
          </button>
        </form>
      </Modal>
    </div>
  )
}

export default InputAssign
