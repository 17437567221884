// context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const MyAuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const login = async (email, password, role) => {
    try {
      const response = await axios.post(
        'https://backend.prolocaledge.com/auth/login',
        {
          email,
          password,
          role,
        }
      )

      const user = response.data
      setCurrentUser(user)
      localStorage.setItem('currentUser', JSON.stringify(user))
    } catch (error) {
      console.error('Error during login:', error)
      // Handle login error, show error message, etc.
    }
  }

  const logout = () => {
    setCurrentUser(null)
    localStorage.removeItem('currentUser')
  }

  const register = async (email, password, role) => {
    try {
      const response = await axios.post(
        'https://backend.prolocaledge.com/auth/register',
        {
          email,
          password,
          role,
        }
      )

      const user = response.data
      setCurrentUser(user)
      localStorage.setItem('currentUser', JSON.stringify(user))
    } catch (error) {
      console.error('Error during registration:', error)
      // Handle registration error, show error message, etc.
    }
  }

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('currentUser'))
    if (storedUser) {
      setCurrentUser(storedUser)
    }
    setLoading(false)
  }, [])

  const value = {
    currentUser,
    login,
    logout,
    register,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

// Renamed export statements
export {
  MyAuthProvider as AuthProvider,
  useAuth as MyUseAuth,
  AuthContext as MyAuthContext,
}
