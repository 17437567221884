// components/AddAgentModal.js
import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import useCURD from '../hooks/useCURD'

const AddAgentModal = ({ show, handleClose, refetch }) => {
  const counties = ['Siaya', 'Kakamega']
  const constituencies = {
    Siaya: ['Gem', 'Alego Usonga', 'Ugenya'],
    Kakamega: ['Mumias'],
  }

  const [agentData, setAgentData] = useState({
    fullName: '',
    email: '',
    age: '',
    nationalId: '',
    phone: '',
    county: '',
    constituency: '',
  })

  const { post } = useCURD()

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setAgentData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleAgentSubmit = (e) => {
    e.preventDefault()
    post('/api/agents', agentData)
      .then(() => {
        refetch()
        handleClose()
      })
      .catch((err) => console.log('Error creating agent', err))
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleAgentSubmit}>
          <Form.Group>
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              value={agentData.fullName}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={agentData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Age</Form.Label>
            <Form.Control
              type="number"
              name="age"
              value={agentData.age}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>National ID</Form.Label>
            <Form.Control
              type="text"
              name="nationalId"
              value={agentData.nationalId}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={agentData.phone}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>County</Form.Label>
            <Form.Control
              as="select"
              name="county"
              value={agentData.county}
              onChange={handleInputChange}
              required>
              <option value="">Select a county</option>
              {counties.map((county) => (
                <option key={county} value={county}>
                  {county}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Constituency</Form.Label>
            <Form.Control
              as="select"
              name="constituency"
              value={agentData.constituency}
              onChange={handleInputChange}
              required
              disabled={!agentData.county}>
              <option value="">Select a constituency</option>
              {agentData.county &&
                constituencies[agentData.county].map((constituency) => (
                  <option key={constituency} value={constituency}>
                    {constituency}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3 mr-2">
            Save Agent
          </Button>
          <Button variant="secondary" onClick={handleClose} className="mt-3">
            Cancel
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddAgentModal
