import React, { useState } from 'react'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import useAPI from './hooks/useAPI'
import { formatName } from './app/utils'

const Orders = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }

  const getTotal = (price, noOfItems) => {
    if (price !== null && noOfItems !== null) {
      return price * noOfItems
    }
  }

  const [orders] = useAPI('/api/orders')
  console.log('orders: ', orders)

  return (
    <div className="grid-container">
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <div className="main-container">
        <h3 className="mt-3 text-black">Orders</h3>
        <table>
          <thead>
            <tr>
              <th>Farmer Name</th>
              <th>Product Name</th>
              <th>Brand</th>
              <th>Inventory Type</th>
              <th>Date of Manufacture</th>
              <th>Type of Fertilizer</th>
              <th>Variety</th>
              <th>Batch No</th>
              <th>Quantity</th>
              <th>No of Item?s</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            {orders.length !== 0 ? (
              orders.map((item, index) => (
                <tr key={index}>
                  <td>{item?.farmer?.fullName}</td>
                  <td>{item?.product?.name}</td>
                  <td>{item?.product?.brand}</td>
                  <td>{item?.product?.inventoryType}</td>
                  <td>{item?.product?.dom}</td>
                  <td>{item?.product?.typeOfFertilizer}</td>
                  <td>{item?.product?.variery}</td>
                  <td>{item?.product?.batchNo}</td>
                  <td>
                    {formatName(item?.product?.quantity, item?.product?.weight)}
                  </td>
                  <td>{item?.numberOfItems}</td>
                  <td>{item?.product?.price}</td>
                  <td>{getTotal(item?.product?.price, item?.numberOfItems)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No data Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Orders
