// Wakulima.js
import React, { useState } from 'react';

import AddFarmerModal from './AddFarmerModal';

const Wakulima = () => {
  // const [searchTerm, setSearchTerm] = useState('');
  // const [foundFarmer, setFoundFarmer] = useState(null);
  // const [newFarmer, setNewFarmer] = useState({
  //   name: '',
  //   age: '',
  //   sex: '',
  //   idNumber: '',
  //   farmSize: '',
  //   location: '',
  //   phoneNumber: '',
  //   county: '',
  //   constituency: '',
  //   ward: '',
  //   village: '',
  // });
  const [showModal, setShowModal] = useState(false);
  const [farmersList, setFarmersList] = useState([]);

  // const handleSearch = async () => {
  //   // ... (same as in your code)
  // };

  const handleAddFarmer = (farmer) => {
    setFarmersList([...farmersList, farmer]);
  };

  // ... (same as in your code)

  return (
    <div className='wakulima-container'>
      {/* ... (existing code) */}
      
      {/* Add Farmer Button */}
      <button onClick={() => setShowModal(true)}>Add Farmer</button>

      {/* Add Farmer Modal */}
      <AddFarmerModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        addFarmer={handleAddFarmer}
      />

      {/* Farmer Details Table */}
      <h3>Farmer Details Table</h3>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Age</th>
            {/* ... (other headers) */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {farmersList.map((farmer, index) => (
            <tr key={index}>
              <td>{farmer.name}</td>
              <td>{farmer.age}</td>
              {/* ... (other fields) */}
              <td>
                <button>Edit</button>
                <button>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Wakulima;


