export const formatNumber = (count = 0) => {
  return Number(count)
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatCurrency = (amount = 0) => {
  return Number(amount)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const cutText = (text, size) => {
  if (!text) return ''
  if (text.length <= size) return text

  return text.substring(0, size) + '...'
}

export const formatName = (title, name) => {
  if (!title) return name

  return `${title} ${name}`
}

export const toUppperCase = (str) => {
  return str.toUpperCase()
}

export const toTitleCase = (str) => {
  return str
    .split('_')
    .join(' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
}
